import Vue from 'vue';
import {mapMutations} from 'vuex';
import {get} from '@/services/api';
import {vehiclesSecondaryOptions} from '../../../services/endpoint.json';

export default Vue.extend({
  name: 'vehicles-secondary-options',
  data: () => ({
    columns: [
      {label:'Option Id',key:'optionId', sortable:true},
      {label:'type',key:'type', sortable:true},
      {label:'name',key:'name', sortable:true},
      {label:'description',key:'description', sortable:true},
    ],
    tableData: [],
    file: '',
  }),

  async created() {
    await this.getDataExcelFileFromDataBase();
  },
  methods: {
    ...mapMutations(['loaderManager']),


    async getDataExcelFileFromDataBase() {
      try {
        this.loaderManager(true);
        const {data} = await get(vehiclesSecondaryOptions, null, true);
        this.loaderManager(false);
        this.tableData = data.message;
      } catch (e) {
        this.loaderManager(false);
      }
    },
  },
});
